<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('contact.create')"
      :title="$t('contact.create')"
      @submitPressed="submitPressed"
    />
    <contacts-form
      v-if="contact"
      :contactObject="contact"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ContactsForm from "./components/ContactsForm.vue";

export default {
  components: {
    TitleBar,
    ContactsForm,
  },
  data() {
    return {
      contact: {},
      action: null,
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
