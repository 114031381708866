<template>
  <b-form @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="12">
          <b-card>
            <b-form-group
                  :class="{ error: v$.contact.sourceSelect.$errors.length }"
                  :label="$t('contact.source')"
                  label-for="h-source"
                  label-cols-md="2"
                  content-cols-lg="4"
              >
                  <v-select
                      id="h-source"
                      v-model="contact.sourceSelect"
                      :options="sources"
                  />
                  <div
                      class="input-errors"
                      v-for="error of v$.contact.sourceSelect.$errors"
                      :key="error.$uid"
                  >
                      <small class="text-danger">{{ error.$message }}</small>
                  </div>
            </b-form-group>

            <b-form-group
              :label="$t('contact.givenName')"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-input v-model="contact.givenName" />
            </b-form-group>

            <b-form-group
              :label="$t('contact.familyName')"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-input v-model="contact.familyName" />
            </b-form-group>

            <b-form-group
              :label="$t('contact.email')"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-input v-model="contact.email" />
            </b-form-group>

            <b-form-group
              :label="$t('contact.telephone')"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-input v-model="contact.telephone" />
            </b-form-group>

            <b-form-group
              :label="$t('contact.note')"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-input v-model="contact.note" />
            </b-form-group>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  props: {
    contactObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
        contact: {
            sourceSelect: { required: this.$translateError('required') },
        }
    };
  },
  async created() {
    this.getEnumList("contact_source", this.sources);
  },
  data() {
    return {
      contact: this.contactObject,
      sources: [],
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          givenName: this.contact.givenName,
          familyName: this.contact.familyName,
          email: this.contact.email,
          telephone: this.contact.telephone,
          note: this.contact.note,
          source: this.contact.sourceSelect.id,
        };

        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Contacts,
                body,
                this.$t("contact.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Contacts,
                this.contact.id,
                body,
                this.$t("contact.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/contacts`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
